import { React, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Row, Button, Badge } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import Avatar, { AvatarGroup } from "components/common/Avatar";
import Flex from "components/common/Flex";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import NothingToShow from "../NothingToShow";
import { stringLimiter } from "helpers/utils";

const UpcomingSchedule = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [item, setItem] = useState([]);
  const Translate = useAxisproTranslate();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await apiCall({
        url: "crm/dashboard/upcoming-schedules",
      });
      if (response) {
        let upComingSchedule = [
          ...response?.meetings.map((meeting) => ({
            ...meeting,
            module: "Meeting",
          })),
          ...response?.calls?.map((call) => ({ ...call, module: "Calling" })),
        ];
        if (upComingSchedule?.length > 0) {
          setItem(upComingSchedule[0]);
        } else {
          setItem([]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const parsedDate = item?.start_time
    ? moment(item.start_time, "DD-MM-YYYY hh:mm A")
    : moment();
  const day = parsedDate.format("DD");
  const month = parsedDate.format("MMM");

  const handleStatusUpdate = async (id, module) => {
    const meetingId = id;
    let url =
      module === "Meeting"
        ? "/crm/meetings/update-status"
        : "/crm/update-status";
    const method = module === "Meeting" ? "patch" : "put";
    if (!meetingId) {
      console.error("Meeting ID is not available");
      return;
    }
    setUpdating(true);
    try {
      await axios({
        method: method,
        url: url,
        data: {
          id: meetingId,
          status: "COMPLETED",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      showToast("Status updated successfully", "success");
      fetchData();
    } catch (error) {
      console.error("Failed to update status:", error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Card className="h-100 card-main">
      {isLoading ? (
        <Card.Body className="px-4 py-5">
          <DashboardTileLoader column={1} />
        </Card.Body>
      ) : (
        <>
          <Card.Header className="pb-0">
            <Flex justifyContent="between">
              <div>
                {Object.keys(item).length > 0 ? (
                  <h6 className="fw-bold fs-0 p-0 mb-2">
                    {Translate("Upcoming schedule")}
                  </h6>
                ) : (
                  <h6 className="fw-bold fs-0 p-0 mb-2">
                    {Translate("No Upcoming Schedule")}
                  </h6>
                )}
              </div>
              <div
                className="bg-primary-subtle px-3 py-3 rounded-circle text-center"
                style={{ width: "54px", height: "52px" }}
              >
                <h5 className="mb-0 d-flex flex-column mt-n1 datecolor">
                  <span className="fw-bold fs-1">{day}</span>
                  <small className="fs-12 lh-1">{month}</small>
                </h5>
              </div>
            </Flex>
          </Card.Header>
          {item && item?.length !== 0 ? (
            <>
              <Card.Body as={Flex} alignItems="end">
                <Row className="g-3 justify-content-between">
                  <Col xs={10} className="mt-n2">
                    <div>
                      <h6 className="fs-1 mb-1 text-info">
                        {item?.module === "Call" ? item?.call_to : item?.title}
                      </h6>
                      <Badge bg="warning" pill>
                        {item?.module}
                      </Badge>
                    </div>
                    {item?.description && (
                      <p className="fs-10 text-dark mb-1">
                        {stringLimiter(item?.description, 60)}
                      </p>
                    )}
                    {item?.start_time && (
                      <p className="fs-10 text-dark mb-1">{`${item?.module} Time - ${item?.start_time}`}</p>
                    )}
                    {item?.reference && (
                      <p className="fs-10 text-dark mb-1">{item?.reference}</p>
                    )}
                  </Col>

                  {Object.keys(item).length > 0 && (
                    <Col xs="auto">
                      <Button
                        variant="success"
                        className="w-100 fs--1 rounded"
                        style={{ backgroundColor: "#5FCE83" }}
                        onClick={() =>
                          handleStatusUpdate(item?.id, item?.module)
                        }
                        disabled={updating}
                      >
                        {updating
                          ? Translate("Updating...")
                          : Translate("Mark as complete")}
                      </Button>
                    </Col>
                  )}

                  <Col xs="auto" className="align-self-end">
                    <AvatarGroup dense>
                      {item?.participants
                        ?.slice(0, 5)
                        .map(({ full_name, id }) => (
                          <Avatar
                            key={id}
                            name={full_name}
                            className="border border-3 rounded-circle border-200"
                          />
                        ))}
                      {item?.participants?.length > 5 && (
                        <Avatar
                          key="extra"
                          name={`${item.participants.length - 5}+`}
                          className="border border-3 rounded-circle border-200"
                          isExact
                        />
                      )}
                    </AvatarGroup>
                  </Col>
                </Row>
              </Card.Body>
            </>
          ) : (
            <>
              <div className="h-100">
                <NothingToShow />
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
};

UpcomingSchedule.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      img: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.string,
    })
  ),
};

export default UpcomingSchedule;
