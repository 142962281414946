import { React, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import CrmStats from "./CrmStats/CrmStats";
import DealStorageFunnel from "./DealStorageFunnel/DealStorageFunnel";
import UpcomingSchedule from "./UpcomingSchedule/UpcomingSchedule";
import TotalCustomerRevenueTile from "./TotalCustomerRevenueTile/TotalCustomerRevenueTile";
import TodoListTile from "./TodoListTile/TodoListTile";
import RevenueForecast from "./RevenueForecast/RevenueForecast";
import EventCalendar from "./EventCalendar/EventCalendar";
import RecentActivityTile from "./RecentActivities/RecentActivityTile";
import { recentActivities } from "./RecentActivities/RecentActivityData";
import Stats from "./Stats/Stats";
import TotalTaskTile from "./TotalTaskTile/TotalTaskTile";
import { totalTask } from "./TotalTaskTile/TotalTaskTileData";
import RunningTaskTile from "./RunningTaskTile/RunningTaskTile";
import CompletedTaskTile from "./CompletedTaskTile/CompletedTaskTile";
import RunningProjects from "./RunningProjects/RunningProjects";
import TicketStatus from "./TicketStatus/TicketStatus";
import SalesPipeLine from "./SalesPipeline/SalesPipeLine";
import { apiCall } from "helpers/apiCalls";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import useAxisproPermission from "hooks/useAxisproPermission";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [refreshTodo, setRefreshTodo] = useState(false);
  const axisProPermission = useAxisproPermission();
  const permission = {
    listLeadOrOpportunity:
      GetAcodaxPermission("LEAD", "list") ||
      GetAcodaxPermission("OPPORTUNITY", "list"),
    listLead: GetAcodaxPermission("LEAD", "list"),
    listOpportunity: GetAcodaxPermission("OPPORTUNITY", "list"),
    showUser: GetAcodaxPermission("USER", "show"),
    showAccount: GetAcodaxPermission("ACCOUNT", "show"),
    listTodo: GetAcodaxPermission("TODO", "list"),
    listLeadAndOpportunity:
      GetAcodaxPermission("LEAD", "list") &&
      GetAcodaxPermission("OPPORTUNITY", "list"),
    listProjectOrTask:
      GetAcodaxPermission("PROJECT", "list") ||
      GetAcodaxPermission("TASK", "show"),
    showTask:
      GetAcodaxPermission("TASK", "show") ||
      GetAcodaxPermission("CT", "view") ||
      GetAcodaxPermission("AT", "view"),
    listProjectDetailedReport: axisProPermission("project-detailed-report"),
    listTickets: GetAcodaxPermission("TICKET", "list"),
  };
  const fetchData = useCallback(async () => {
    if (
      permission.showAccount ||
      permission.listProjectOrTask ||
      permission.showTask ||
      permission.listTickets
    ) {
      try {
        setIsLoading(true);
        const response = await apiCall({ url: "crm/dashboard-data" });
        setData(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    permission.listProjectOrTask,
    permission.listTickets,
    permission.showAccount,
    permission.showTask,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="pb-3 d-flex flex-column gap-3">
      <Row className="g-3">
        {(permission.listLeadOrOpportunity ||
          permission.listLead ||
          permission.listOpportunity ||
          permission.showUser) && (
          <Col xxl={9} xl={12}>
            <CrmStats />
          </Col>
        )}
        {permission.listLeadOrOpportunity && (
          <Col xxl={3} className="d-none d-xxl-block">
            <UpcomingSchedule />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listLeadOrOpportunity && (
          <Col xl={3} lg={3} className="d-none d-xxl-none d-lg-block">
            <UpcomingSchedule />
          </Col>
        )}
        {permission.listLead && (
          <Col xxl={4} xl={5} lg={5} md={6}>
            <SalesPipeLine />
          </Col>
        )}
        {permission.listOpportunity && (
          <Col xxl={3} xl={4} lg={4} md={6}>
            <DealStorageFunnel />
          </Col>
        )}
        {permission.listLeadOrOpportunity && (
          <Col md={4} className="d-none d-xxl-none d-md-block d-lg-none">
            <UpcomingSchedule />
          </Col>
        )}
        {permission.showAccount && (
          <Col xxl={2} xl={4} lg={4} md={4}>
            <TotalCustomerRevenueTile data={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.listTodo && (
          <Col xxl={3} xl={8} lg={8} md={4}>
            <TodoListTile refreshTodo={refreshTodo} />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listLeadAndOpportunity && (
          <Col xxl={4} xl={12}>
            <RevenueForecast />
          </Col>
        )}
        {permission.listTodo && (
          <Col xxl={5} xl={7} lg={7} md={7}>
            <EventCalendar setRefreshTodo={setRefreshTodo} />
          </Col>
        )}
        <Col xxl={3} xl={5} lg={5} md={5}>
          <RecentActivityTile data={recentActivities} />
        </Col>
      </Row>
      <Row className="g-3">
        {permission.listProjectOrTask && (
          <Col md={12} lg={12} xl={12} xxl={5}>
            <Stats data={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.showTask && (
          <Col md={4} lg={4} xl={4} xxl={2}>
            <TotalTaskTile data={totalTask} item={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.showTask && (
          <Col md={4} lg={4} xl={4} xxl={2}>
            <RunningTaskTile data={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.showTask && (
          <Col md={4} lg={4} xl={4} xxl={3}>
            <CompletedTaskTile data={data} isLoading={isLoading} />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listProjectDetailedReport && (
          <Col xxl={9} xl={8} lg={7} md={7}>
            <RunningProjects />
          </Col>
        )}
        {permission.listTickets && (
          <Col xxl={3} xl={4} lg={5} md={5}>
            <TicketStatus data={data} isLoading={isLoading} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
